import React from 'react';
import { graphql } from 'gatsby';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

import HomeView from '../components/Homepage/v2/HomePage';
import { ContentfulHomepage } from '../graphql-types';
import {
  ELIGIBILITY_CHECKER_MARKETING_LP_LINK,
  HUBSPOT_MEETING_IBRAHIM_LINK,
  REQUEST_MATERIALS_MARKETING_LP_FORM_FORM_ID,
  REQUEST_MATERIALS_MARKETING_LP_FORM_PORTAL_ID,
  REZEPT_URL_MARKETING_LP,
} from '../utils/constants';

interface OwnProps {
  pathContext: {
    locale: 'en' | 'de';
  };
  data: {
    homepage: ContentfulHomepage;
  };
}

type Props = OwnProps & WrappedComponentProps;

const Homepage: React.FC<Props> = ({
  pathContext: { locale },
  data: { homepage },
}) => {
  return (
    <Layout
      simpleHeader
      simpleFooter
      isNewLayout
      prescriptionLink={REZEPT_URL_MARKETING_LP}
    >
      <SEO
        title={homepage.seoTitle}
        description={homepage.seoDescription}
        lang={locale}
        noindex
      />

      <HomeView
        homepage={homepage}
        eliCheckerLink={ELIGIBILITY_CHECKER_MARKETING_LP_LINK}
        requestMaterialsFormId={REQUEST_MATERIALS_MARKETING_LP_FORM_FORM_ID}
        requestMaterialsFormPortalId={
          REQUEST_MATERIALS_MARKETING_LP_FORM_PORTAL_ID
        }
        bookAppointmentLink={HUBSPOT_MEETING_IBRAHIM_LINK}
        rezeptUrl={REZEPT_URL_MARKETING_LP}
      />
    </Layout>
  );
};

export default injectIntl(Homepage);

export const secondContentfulHomepage = graphql`
  query secondContentfulHomepage($locale: String!) {
    homepage: contentfulHomepage(node_locale: { eq: $locale }) {
      seoTitle
      seoDescription
      heroTitle
      heroText {
        childMarkdownRemark {
          rawMarkdownBody
          html
        }
      }
      appStoreDownloadLink {
        url
        isDisplayed
      }
      googlePlayStoreDownloadLink {
        url
        isDisplayed
      }
      video {
        text
        url
        isDisplayed
      }
      chatBubbles {
        id
        heading
        shortText
        image {
          fixed(width: 40) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
      logos {
        id
        url
        image {
          title
          fixed(width: 120) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
      scienceSection {
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        items {
          id
          heading
          shortText
          image {
            fluid(maxHeight: 73) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        callToAction {
          text
          url
          isDisplayed
        }
      }
      trackingSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        items {
          id
          prefix
          heading
          shortText
          image {
            fluid(maxWidth: 275) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      employersSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        callToAction {
          text
          url
          isDisplayed
        }
      }
      personalizedPrograms {
        prefix
        title
        items {
          id
          heading
          shortText
        }
        callToAction {
          text
          url
          isDisplayed
        }
      }
      missionSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        callToAction {
          text
          url
          isDisplayed
        }
      }
      scienceAdvisor {
        prefix
        title
        items {
          id
          heading
          image {
            fluid(maxHeight: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          shortText
        }
      }
      featureHeader {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        callToAction {
          text
          url
          isDisplayed
        }
        items {
          id
          heading
        }
      }
      testimonialsHeader {
        shortText
        heading
        prefix
      }
      testimonialsList {
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;
